<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HYXW' }">会议新闻</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HY_list' }">重要会议</el-breadcrumb-item>
      <el-breadcrumb-item>会议详情</el-breadcrumb-item>
    </el-breadcrumb>

    <div id="js_article" style="position:relative;" class="rich_media">

      <div id="js_top_ad_area" class="top_banner"></div>

      <div id="js_base_container" class="rich_media_inner">


        <div id="page-content" class="rich_media_area_primary" style="">
          <div class="rich_media_area_primary_inner">


            <div id="img-content" class="rich_media_wrp">

              <h1 class="rich_media_title " id="activity-name">

                <el-link :href="yourExternalPageURL" class="rich_media_title"
                  target="_blank">【协和帮扶】贵州省放射影像专业质控中心2024年上半年工作会召开（转载）</el-link>
              </h1>
              <div id="meta_content" class="rich_media_meta_list">

                <span class="rich_media_meta rich_media_meta_nickname" id="profileBt">
                  <a href="javascript:void(0);" class="wx_tap_link js_wx_tap_highlight weui-wa-hotarea" id="js_name">
                    贵州医科大学附属医院党委宣传部 </a>
                  <div id="js_profile_qrcode" aria-hidden="true" class="profile_container" style="display:none;">
                    <div class="profile_inner">
                      <strong class="profile_nickname">贵州医科大学附属医院党委宣传部</strong>
                      <img class="profile_avatar" id="js_profile_qrcode_img" src="" alt="">

                      <p class="profile_meta">
                        <label class="profile_meta_label">微信号</label>
                        <span class="profile_meta_value">ykdxfsyy</span>
                      </p>

                      <p class="profile_meta">
                        <label class="profile_meta_label">功能介绍</label>
                        <span
                          class="profile_meta_value">贵州医科大学附属医院建于1941年，是西南地区创办较早的高等医学院校附属医院之一。我院是贵州省综合实力最强、集医疗教学科研为一体的大型综合性三级甲等医院。</span>
                      </p>
                    </div>
                    <span class="profile_arrow_wrp" id="js_profile_arrow_wrp">
                      <i class="profile_arrow arrow_out"></i>
                      <i class="profile_arrow arrow_in"></i>
                    </span>
                  </div>


                </span>
                <span id="meta_content_hide_info">
                  <em id="publish_time" class="rich_media_meta rich_media_meta_text">2024年07月11日 22:05</em>
                  <em id="js_ip_wording_wrp" class="rich_media_meta rich_media_meta_text" role="option"
                    aria-labelledby="js_a11y_op_ip_wording js_ip_wording" style="display: inline-block;"><span
                      id="js_a11y_op_ip_wording" aria-hidden="true"></span><span aria-hidden="true"
                      id="js_ip_wording">贵州</span></em>
                  <em id="js_title_modify_wrp" class="rich_media_meta rich_media_meta_text" role="option"
                    aria-labelledby="js_a11y_op_title_modify js_title_modify" style="display: none;"><span
                      aria-hidden="true" id="js_title_modify"></span></em>
                </span>
              </div>


              <div class="rich_media_content js_underline_content
                       autoTypeSetting24psection
            " id="js_content" style="">
                <section style="font-size: 16px; visibility: visible;">
                  <section style="visibility: visible;">
                    <section style="color: rgb(0, 0, 0); letter-spacing: 2px; line-height: 2; visibility: visible;">
                      <p style="text-align: center; margin-bottom: 0px; visibility: visible;">
                        <img class="rich_pages wxw-img js_insertlocalimg" data-imgfileid="100126550"
                          data-ratio="0.3648148148148148" data-s="300,640" data-src="@/assets/HY20240712/1.png"
                          data-type="webp" data-w="1080"
                          style="height: auto !important; visibility: visible !important; width: 677px !important;"
                          data-original-style="" data-index="2" src="@/assets/HY20240712/1.png" _width="677px"
                          crossorigin="anonymous" alt="图片" data-fail="0">
                      </p>
                      <p style="margin-bottom: 0px; visibility: visible;"><br style="visibility: visible;"></p>
                      <p style="text-indent: 2em; white-space: normal; visibility: visible;">
                        7月9日，贵州省放射影像专业质控中心召开了2024年上半年工作总结会。国家放射影像专业质控中心主任、北京协和医院放射科主任医师金征宇，<span
                          style="color: rgb(0, 0, 0); font-size: 16px; letter-spacing: 2px; text-indent: 32px; text-wrap: wrap; visibility: visible;">国家放射影像专业质控中心</span>副主任、北京协和医院放射科副主任兼西院放射科主任薛华丹，<span
                          style="color: rgb(0, 0, 0); font-size: 16px; letter-spacing: 2px; text-indent: 32px; text-wrap: wrap; visibility: visible;">国家放射影像</span><span
                          style="color: rgb(0, 0, 0); font-size: 16px; letter-spacing: 2px; text-indent: 32px; text-wrap: wrap; visibility: visible;">专业质控中心</span>秘书、<span
                          style="color: rgb(0, 0, 0); font-size: 16px; letter-spacing: 2px; text-indent: 32px; text-wrap: wrap; visibility: visible;">北京协和医院放射</span><span
                          style="color: rgb(0, 0, 0); font-size: 16px; letter-spacing: 2px; text-indent: 32px; text-wrap: wrap; visibility: visible;">科主任医师</span>孙昊线上参会。我院医务处处长、质管处副处长莫非，贵州省放射影像专业质控中心主任、我院影像科主任高波，省放射影像专业质控中心副主任委员、省人民医院影像科主任王荣品，质控中心委员毛巨江及9个市州质控中心主任和秘书现场参会。省放射影像专业质控中心副主任委员、我院影像科副主任阮志兵主持。会议旨在通过对上半年工作的全面总结，审视取得的成绩和存在的问题，为下步工作提出方向性意见，进一步提升我省放射影像专业服务质量和专业水平。
                      </p>
                    </section>
                  </section>
                  <p style="white-space: normal; visibility: visible;"><br style="visibility: visible;"></p>
                  <section style="text-align: center; line-height: 0; visibility: visible;">
                    <section
                      style="vertical-align: middle; display: inline-block; line-height: 0; visibility: visible;"><img
                        class="rich_pages wxw-img" data-imgfileid="100126506" data-ratio="0.75" data-s="300,640"
                        data-src="@/assets/HY20240712/2.png" data-type="png" data-w="1080"
                        style="vertical-align: middle; width: 677px !important; height: auto !important; visibility: visible !important;"
                        data-original-style="vertical-align: middle;width: 100%;" data-index="3"
                        src="@/assets/HY20240712/2.png" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0">
                    </section>
                  </section>
                  <p style="white-space: normal;"><br></p>
                  <section style="">
                    <section style="color: rgb(0, 0, 0);letter-spacing: 2px;line-height: 2;">
                      <p style="text-indent: 2em;white-space: normal;">
                        金征宇对贵州省放射影像专业质控中心工作给予充分肯定，并强调，要进一步细化同质化工作，加强与国家放射影像专业质控中心的沟通和交流，以标准化和规范化提升全省放射影像同质化水平。</p>
                    </section>
                  </section>
                  <p style="white-space: normal;"><br></p>
                  <section style="text-align: center;line-height: 0;">
                    <section style="vertical-align: middle;display: inline-block;line-height: 0;"><img
                        class="rich_pages wxw-img" data-imgfileid="100126505" data-ratio="0.525" data-s="300,640"
                        data-src="@/assets/HY20240712/3.png" data-type="png" data-w="1080"
                        style="vertical-align: middle; width: 677px !important; height: auto !important; visibility: visible !important;"
                        data-original-style="vertical-align: middle;width: 100%;" data-index="4"
                        src="@/assets/HY20240712/3.png" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0">
                    </section>
                  </section>
                  <p style="white-space: normal;"><br></p>
                  <section style="">
                    <section style="color: rgb(0, 0, 0);letter-spacing: 2px;line-height: 2;">
                      <p style="text-indent: 2em;white-space: normal;">
                        薛华丹强调了国家放射影像专业质控指标推进工作的重要性，并对贵州省推进指标实施工作给予高度评价。她表示，希望贵州省各级医疗机构在省质控中心带领下，进一步提升放射影像专业能力和水平，让老百姓能真正享受到高质量的医疗服务。
                      </p>
                    </section>
                  </section>
                  <p style="white-space: normal;"><br></p>
                  <section style="text-align: center;line-height: 0;">
                    <section style="vertical-align: middle;display: inline-block;line-height: 0;"><img
                        class="rich_pages wxw-img" data-imgfileid="100126504" data-ratio="0.5" data-s="300,640"
                        data-src="@/assets/HY20240712/4.png" data-type="png" data-w="1080"
                        style="vertical-align: middle; width: 677px !important; height: auto !important; visibility: visible !important;"
                        data-original-style="vertical-align: middle;width: 100%;" data-index="5"
                        src="@/assets/HY20240712/4.png" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0">
                    </section>
                  </section>
                  <p style="white-space: normal;"><br></p>
                  <section style="">
                    <section style="color: rgb(0, 0, 0);letter-spacing: 2px;line-height: 2;">
                      <p style="text-indent: 2em;white-space: normal;">
                        孙昊以放射影像质控指标内涵和建立流程为主题，对2024版放射影像专业质控指标的制定和应用作了详细解读。他表示，各级各类医疗机构要充分认识质控指标的重要意义，准确把握质控指标的定位和内涵，发挥质控指标的工具性作用，结合自身实际情况，利用质控指标开展自我管理，不断提升医疗质量管理的科学化、精细化、规范化水平，保障医疗质量安全。
                      </p>
                    </section>
                  </section>
                  <p style="white-space: normal;"><br></p>
                  <section style="text-align: center;line-height: 0;">
                    <section style="vertical-align: middle;display: inline-block;line-height: 0;"><img
                        class="rich_pages wxw-img" data-imgfileid="100126503" data-ratio="0.5138888888888888"
                        data-s="300,640" data-src="@/assets/HY20240712/5.png" data-type="png" data-w="1080"
                        style="vertical-align: middle; width: 677px !important; height: auto !important; visibility: visible !important;"
                        data-original-style="vertical-align: middle;width: 100%;" data-index="6"
                        src="@/assets/HY20240712/5.png" 
                        _width="100%" crossorigin="anonymous" alt="图片" data-fail="0">
                    </section>
                  </section>
                  <p style="white-space: normal;"><br></p>
                  <section style="">
                    <section style="color: rgb(0, 0, 0);letter-spacing: 2px;line-height: 2;">
                      <p style="text-indent: 2em;margin-bottom: 8px;white-space: normal;">
                        莫非表示，上半年，省放射影像质控中心以调研及督导检查工作为抓手，以问题为导向，从质控工作细微处着手，切实有效对基层开展了帮扶和指导。随着医学技术的迅猛发展，人民群众对医疗服务的需求日益增长，需要进一步健全质控标准和规范，形成科学合理的质控体系，加强质控管理，提高专业能力水平，推动放射影像专业持续健康发展，为人民群众的健康提供更加优质的医疗服务。
                      </p>
                      <p style="text-indent: 2em;margin-bottom: 8px;white-space: normal;">
                        在工作汇报环节，高波对全省上半年的质控数据及工作成效作了分析总结，同时指出存在的问题和下步工作计划。九个市州质控中心主任分别对上半年工作依次作了汇报。王荣品作了精彩点评，并表示，质控是学科发展的基石和生命线，不仅要做好质控，更要实现同质化，不断提升服务质量。
                      </p>
                      <p style="text-indent: 2em;white-space: normal;">
                        高波总结表示，我省质控工作取得的成绩离不开国家放射影像质控中心的指导和全省同仁的共同努力。在国家质控中心帮扶指导下，我省质控工作有了更加明确的目标和方向。下步，我们将以质控指标为抓手，将质控工作贯彻到医疗服务的各方面，进一步加强质控管理，提高专业水平，推动贵州省放射影像专业持续健康发展。
                      </p>
                    </section>
                  </section>
                </section>
                <section
                  style="margin-top: 5px;margin-bottom: 5px;white-space: normal;outline: 0px;letter-spacing: 0.544px;color: rgb(34, 34, 34);font-size: 14px;caret-color: rgb(0, 0, 0);font-family: -webkit-standard;-webkit-tap-highlight-color: rgba(26, 26, 26, 0.3);text-size-adjust: none;visibility: visible;line-height: 1.5em;">
                  <span style="outline: 0px;visibility: visible;color: rgb(136, 136, 136);"><br></span>
                </section>
                <section
                  style="margin-top: 5px;margin-bottom: 5px;white-space: normal;outline: 0px;letter-spacing: 0.544px;color: rgb(34, 34, 34);font-size: 14px;caret-color: rgb(0, 0, 0);font-family: -webkit-standard;-webkit-tap-highlight-color: rgba(26, 26, 26, 0.3);text-size-adjust: none;visibility: visible;line-height: 1.5em;">
                  <span style="outline: 0px;visibility: visible;color: rgb(136, 136, 136);">作者：影像科 刘敏<span
                      style="outline: 0px;letter-spacing: 0.544px;"></span></span>
                </section>
                <section
                  style="margin-top: 5px;margin-bottom: 5px;white-space: normal;outline: 0px;letter-spacing: 0.544px;color: rgb(34, 34, 34);font-size: 14px;caret-color: rgb(0, 0, 0);font-family: -webkit-standard;-webkit-tap-highlight-color: rgba(26, 26, 26, 0.3);text-size-adjust: none;visibility: visible;line-height: 1.5em;">
                  <span style="outline: 0px;visibility: visible;color: rgb(136, 136, 136);">编辑：彭楠芮<br
                      style="outline: 0px;"></span>
                </section>
                <section
                  style="margin-top: 5px;margin-bottom: 5px;white-space: normal;outline: 0px;letter-spacing: 0.544px;color: rgb(34, 34, 34);font-size: 14px;caret-color: rgb(0, 0, 0);font-family: -webkit-standard;-webkit-tap-highlight-color: rgba(26, 26, 26, 0.3);text-size-adjust: none;visibility: visible;line-height: 1.5em;">
                  <span style="outline: 0px;visibility: visible;color: rgb(136, 136, 136);"><span
                      style="outline: 0px;letter-spacing: 0.544px;visibility: visible;">本期</span>一审：夏天 杨欣晨 舒星</span>
                </section>
                <section
                  style="margin-top: 5px;margin-bottom: 5px;white-space: normal;outline: 0px;letter-spacing: 0.544px;color: rgb(34, 34, 34);font-size: 14px;caret-color: rgb(0, 0, 0);font-family: -webkit-standard;-webkit-tap-highlight-color: rgba(26, 26, 26, 0.3);text-size-adjust: none;visibility: visible;line-height: 1.5em;">
                  <span style="outline: 0px;visibility: visible;color: rgb(136, 136, 136);"><span
                      style="outline: 0px;letter-spacing: 0.544px;visibility: visible;">本期</span>二审：张峰</span>
                </section>
                <section
                  style="margin-top: 5px;margin-bottom: 0em;white-space: normal;outline: 0px;letter-spacing: 0.544px;color: rgb(34, 34, 34);font-size: 14px;caret-color: rgb(0, 0, 0);font-family: -webkit-standard;-webkit-tap-highlight-color: rgba(26, 26, 26, 0.3);text-size-adjust: none;visibility: visible;line-height: 1.5em;">
                  <span style="outline: 0px;visibility: visible;color: rgb(136, 136, 136);"><span
                      style="outline: 0px;letter-spacing: 0.544px;visibility: visible;">本期</span>三审：吴祥文 黄宁玉</span>
                </section>
                <!-- <p style="text-align: center;margin-bottom: 0px;"><img class="rich_pages wxw-img" data-galleryid=""
                    data-imgfileid="100126509" data-ratio="1.8944444444444444" data-s="300,640"
                    data-src="https://mmbiz.qpic.cn/mmbiz_jpg/LMdKBruXNosKK251mVepAHDFrXvPcFAqBbj9TSiaj7hATcGp43iaPCbXJdCfP9zFLWKYlSB5LzQ95ib1L7smtRj0A/640?wx_fmt=jpeg&amp;from=appmsg"
                    data-type="jpeg" data-w="1080"
                    style="height: auto !important; visibility: visible !important; width: 677px !important;"
                    data-original-style="" data-index="7"
                    src="https://mmbiz.qpic.cn/mmbiz_jpg/LMdKBruXNosKK251mVepAHDFrXvPcFAqBbj9TSiaj7hATcGp43iaPCbXJdCfP9zFLWKYlSB5LzQ95ib1L7smtRj0A/640?wx_fmt=jpeg&amp;from=appmsg&amp;tp=webp&amp;wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
                    _width="677px" crossorigin="anonymous" alt="图片" data-fail="0"></p>
                <p style="margin-bottom: 0px;"><br></p>
                <p style="display: none;"><mp-style-type data-value="3"></mp-style-type></p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { getZXInfo } from '@/api'
export default {
  name: "XSJX",
  data() {
    return {
      list: [],
      yourExternalPageURL: "https://mp.weixin.qq.com/s/CqBDU9besENaP1DTqXSvBw",
    }
  },
  methods: {
    async getZXInfoAsync() {
      const r = await getZXInfo();
      if (r.code == 1) {
        this.list = r.data;
      } else {
        this.$message.error(r.error.Message);
      }
    },
    handleClick(row) {
      const link = row.Link;
      window.open(link)
    },
  },
  created() {
    // this.getZXInfoAsync();
  },
}
</script>

<style scoped>
.card_box {
  width: 350px;
  cursor: pointer;
}

.card_img {
  width: 300px;
}

.rich_media_area_primary_inner {
  max-width: 677px;
  margin: 0 auto;
}

.rich_media_meta_list {
  margin-bottom: 22px;
  line-height: 20px;
  font-size: 0;
  word-wrap: break-word;
  hyphens: auto;
}

.rich_media_meta {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 10px 0;
  font-size: 15px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  color: #576b95;
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rich_media_meta_list em {
  font-style: normal;
}

.rich_media_meta_text {
  color: rgba(255, 255, 255, .35);
  color: rgba(0, 0, 0, .3);
}

.rich_media_title {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 14px;
}
</style>